import { ITableQuery } from 'components/Shared/EnchancedTable/types';

export const DEFAULT_CONNECTION_TABLE_QUERY: ITableQuery = {
  isActive: true
};

export const DEFAULT_CONVERSATION_TABLE_QUERY: ITableQuery = {
  isRead: false,
  limit: 100,
  sort: 'lastMessage,-1'
};
