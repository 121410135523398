import { NavItemType } from 'types';
import { ROUTE_POCKETS } from 'constants/routes';
import { PERMISSION_POCKETS } from 'constants/permissions';
import { IconBrandPocket } from '@tabler/icons-react';
import { STYLES_ICON } from 'components/Chat/constants/messsageMediaType';

export const pockets: NavItemType = {
  id: 'pockets',
  title: 'pockets',
  type: 'group',
  children: [
    {
      id: 'pockets',
      title: 'pockets',
      type: 'item',
      url: ROUTE_POCKETS,
      icon: (props) => <IconBrandPocket style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_POCKETS
    }
  ]
};
