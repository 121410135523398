import { NavItemType } from 'types';
import { IconCalendar } from '@tabler/icons';
import { PERMISSION_CALENDAR } from 'constants/permissions';
import { ROUTE_CALENDAR } from 'constants/routes';
import { STYLES_ICON } from 'components/Chat/constants/messsageMediaType';

export const calendar: NavItemType = {
  id: 'Calendar',
  title: 'calendar',
  type: 'group',
  children: [
    {
      id: 'EventCalendar',
      title: 'calendar',
      type: 'item',
      url: ROUTE_CALENDAR,
      icon: (props) => <IconCalendar style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_CALENDAR
    }
  ]
};
