import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Avatar, Box, Paper, Typography } from '@mui/material';
import { CallDuration } from './CallDuration';
import { CallActions } from './CallActions';
import { useCall } from 'hooks';
import { useTranslation } from 'hooks/useTranslation';
import useTeam from 'hooks/useTeam';

let device;

export const CallCard = React.memo(() => {
  const { translate } = useTranslation();

  const { team } = useTeam();

  const { callInfo, generateToken } = useCall();

  const [callStatus, setCallStatus] = useState('Call.GeneratingToken');

  const { hangUpCall } = useCall();

  const [position, setPosition] = useState({ x: -300, y: 0 });

  const memoizedPosition = useMemo(() => position, [position.x, position.y]);

  const handleDrag = useCallback((event: DraggableEvent, data: DraggableData) => {
    event.preventDefault();
    console.log('data', data);

    setPosition({ x: data.x, y: data.y });
  }, []);

  const updateCallStatus = (status: string) => setCallStatus(status);

  const refreshToken = async () => {
    const newTwilioToken = await generateToken(team?._id);
    device.updateToken(newTwilioToken);
  };

  const setupDevice = async () => {
    try {
      const { Device } = await import('twilio-client');

      const twilioToken = await generateToken(team?._id);

      device = new Device(twilioToken);

      device.on('ready', () => {
        updateCallStatus('Call.CallReady');
      });

      device.on('error', async (error: { code: number; message: string }) => {
        if (error.code === 31205) {
          await refreshToken();
        } else updateCallStatus('Error: ' + error.message);
      });

      device.on('accept', () => {
        updateCallStatus('Call.Connected');
      });

      await device.on('connect', () => {
        updateCallStatus('Call.InCallWith');
      });

      device.on('disconnect', () => {
        hangUpCall();
        updateCallStatus('Call.CallReady');
      });

      device.on('ringing', () => {
        updateCallStatus('Call.Ringing');
      });

      device.on('tokenWillExpire', async () => {
        await refreshToken();
      });
    } catch (error) {
      if (error instanceof Error) {
        setCallStatus('Error: ' + error.message);
      } else {
        setCallStatus('Error: ' + String(error));
      }
    }
  };

  useEffect(() => {
    setupDevice();
  }, []);

  if (!callInfo) return null;

  return (
    <Draggable onDrag={handleDrag} position={memoizedPosition}>
      <Box
        component={Paper}
        sx={{
          paddingX: 2,
          paddingY: 1.5,
          width: 300,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          cursor: 'move',
          position: 'fixed',
          zIndex: 99999
        }}
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" gap={1} alignItems="center" flexGrow={1}>
            <Avatar sx={{ width: 42, height: 42 }} alt={'Mofin Fraise'} src={''} />
            <Box display="flex" flexDirection="column">
              <Typography variant="h4" color="textPrimary">
                {callInfo.name}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {callInfo.phone}
              </Typography>
            </Box>
          </Box>
          <Typography variant="caption" color="textSecondary" alignSelf="flex-start">
            {translate(callStatus)}
          </Typography>
        </Box>
        {callStatus === 'Call.InCallWith' && <CallDuration />}
        <CallActions device={device} />
      </Box>
    </Draggable>
  );
});
