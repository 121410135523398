import React, { useEffect, Fragment } from 'react';
import { channel } from 'service/pusher';
import { CONVERSATION_CREATED_OR_UPDATED_ALERT, CONNECTION_DISABLE_ACCOUNT_ALERT } from './constants/pusher';
import useTeam from 'hooks/useTeam';
import useConversation from 'hooks/useConversation';
import { useTranslation } from 'hooks/useTranslation';
import { useSnackbar } from 'notistack';
import { SnackBar } from 'components/SnackBar';
import useAccount from 'hooks/useAdAccount';
import { DEFAULT_CONVERSATION_TABLE_QUERY, DEFAULT_CONNECTION_TABLE_QUERY } from 'components/ConnectionsWspBaileys/constants/defaultData';
import { ROUTE_CONVERSATIONS } from 'constants/routes';
import { useRouter } from 'next/router';

export const KraquenNotifications = () => {
  const { team } = useTeam();
  const { addConversationSocket, getConversationsNoRead, conversation, conversations } = useConversation();
  const { translate } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getAdvancedSearchAdAccounts } = useAccount();
  const router = useRouter();

  const alertNotification = (message: string) => {
    const notificationSound = new Audio('/notification.mp3');
    notificationSound.play();
    enqueueSnackbar(translate(message), {
      content: (key, message) => <SnackBar id={key} message={message} type="success" />,
      anchorOrigin: { vertical: 'top', horizontal: 'right' }
    });
  };

  const conversationNotification = (teamId: string) => {
    channel.unbind(`${CONVERSATION_CREATED_OR_UPDATED_ALERT}${teamId}`);
    const handleConversation = (data: { team: string; conversationId: string }) => {
      if (data.team === teamId) {
        addConversationSocket(data.conversationId);
        getConversationsNoRead({ team: data.team, ...DEFAULT_CONVERSATION_TABLE_QUERY });
        if (router.pathname !== ROUTE_CONVERSATIONS) {
          alertNotification('Conversation.NewMessage');
        }
      }
    };
    channel.bind(`${CONVERSATION_CREATED_OR_UPDATED_ALERT}${teamId}`, handleConversation);
  };

  const connectionNotification = (teamId: string) => {
    channel.unbind(`${CONNECTION_DISABLE_ACCOUNT_ALERT}${teamId}`);
    channel.bind(`${CONNECTION_DISABLE_ACCOUNT_ALERT}${teamId}`, (data: { teamId: string }) => {
      if (data.teamId === teamId) getAdvancedSearchAdAccounts({ team: data.teamId, ...DEFAULT_CONNECTION_TABLE_QUERY });
      alertNotification('Connection.DisableAccount');
    });
  };

  useEffect(() => {
    if (team?._id) {
      conversationNotification(team?._id);
      connectionNotification(team?._id);
    }
  }, [team]);

  useEffect(() => {
    if ((conversations?.length > 0 || conversation?._id) && team?._id) {
      getConversationsNoRead({ team: team?._id, ...DEFAULT_CONVERSATION_TABLE_QUERY });
    }
  }, [conversations, conversation, team]);

  return <Fragment />;
};
