import { NavItemType } from 'types';
import { STYLES_ICON } from 'components/Chat/constants/messsageMediaType';
import {
  PERMISSION_GOOGLE_ADS,
  PERMISSION_INSTAGRAM,
  PERMISSION_META_ADS,
  PERMISSION_META_INSIGHTS,
  PERMISSION_TIKTOK,
  PERMISSION_YOUTUBE
} from 'constants/permissions';
import { IconBrandMeta, IconChartArcs, IconBrandGoogle, IconBrandYoutube, IconBrandInstagram, IconBrandTiktok } from '@tabler/icons';
import { ROUTE_META_ADS, ROUTE_META_INSIGHTS, ROUTE_INSTAGRAM, ROUTE_TIKTOK, ROUTE_GOOGLE_ADS, ROUTE_YOUTUBE } from 'constants/routes';

export const analytics: NavItemType = {
  id: 'analytics',
  title: 'analytics',
  type: 'group',
  children: [
    {
      id: 'facebook',
      title: 'meta-ads',
      type: 'item',
      url: ROUTE_META_ADS,
      icon: (props) => <IconBrandMeta style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_META_ADS
    },
    {
      id: 'insights',
      title: 'meta-insights',
      type: 'item',
      url: ROUTE_META_INSIGHTS,
      icon: (props) => <IconChartArcs style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_META_INSIGHTS
    },
    {
      id: 'instagram',
      title: 'instagram',
      type: 'item',
      url: ROUTE_INSTAGRAM,
      icon: (props) => <IconBrandInstagram style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_INSTAGRAM
    },
    {
      id: 'google',
      title: 'google-ads',
      type: 'item',
      url: ROUTE_GOOGLE_ADS,
      icon: (props) => <IconBrandGoogle style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_GOOGLE_ADS
    },
    {
      id: 'youtube',
      title: 'youtube',
      type: 'item',
      url: ROUTE_YOUTUBE,
      icon: (props) => <IconBrandYoutube style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_YOUTUBE
    },
    {
      id: 'tiktok',
      title: 'tiktok',
      type: 'item',
      url: ROUTE_TIKTOK,
      icon: (props) => <IconBrandTiktok style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_TIKTOK
    }
  ]
};
