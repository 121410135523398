import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { GuardProps } from 'types';
import SimpleBackdrop from 'components/BackDrop';
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(true);
  const { loadUser, isAuthenticated, statusCode, clearState } = useAuth();

  const handleLoadUser = () => {
    const token = localStorage.getItem('token');
    if (!token) router.push('/login');
    else loadUser();
  };

  useEffect(() => {
    handleLoadUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) setLoading(false);
    else setLoading(true);
  }, [isAuthenticated]);

  useEffect(() => {
    if (statusCode === 401) {
      router.push('/login');
      localStorage.clear();
      clearState();
    }
  }, [statusCode]);

  if (loading) return <SimpleBackdrop loading={loading} />;

  return children;
};

export default AuthGuard;
