import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { getCallTime } from 'utils';

let interval: NodeJS.Timeout;

export const CallDuration = React.memo(() => {
  const [seconds, setSeconds] = useState(0);

  const updateTime = async () => {
    interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);
  };

  useEffect(() => {
    updateTime();
    return () => clearInterval(interval);
  }, []);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography variant="h6" fontWeight={700}>
        {getCallTime(seconds)}
      </Typography>
    </Box>
  );
});
