import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CallCard } from './components';

/**
 * Represents the call card that is being dragged
 *
 * @return {*}
 */
export const CallDraggableCard = () => {
  return (
    <AnimatePresence>
      <motion.div style={{ top: 5, right: 5, position: 'fixed', zIndex: 10000 }}>
        <CallCard />
      </motion.div>
    </AnimatePresence>
  );
};
