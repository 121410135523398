import { home } from './home';
import { analytics } from './analytics';
import { automation } from './automation';
import { pockets } from './pockets';
import { calendar } from './calendar';
import { conversation } from './conversations';
import { crm } from './crm';
import { settings } from './settings';
import { NavItemType } from 'types';

const menuItems: { items: NavItemType[] } = {
  items: [home, analytics, automation, pockets, calendar, conversation, crm, settings]
};

export default menuItems;
