import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

export default function SimpleBackdrop({ loading }) {
  const theme = useTheme();
  return (
    <div data-testid="container">
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          color: '#fff'
        }}
        open={loading}
        data-testid="backdrop"
      >
        <Grid container>
          <Grid item md={12}>
            <Box display="flex" justifyContent="center">
              <Box p={1} data-testid="icon">
                <CircularProgress color="inherit" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Backdrop>
    </div>
  );
}
