import { NavItemType } from 'types';
import { IconMessages } from '@tabler/icons';
import { PERMISSION_CONVERSATION } from 'constants/permissions';
import { ROUTE_CONVERSATIONS } from 'constants/routes';
import { STYLES_ICON } from 'components/Chat/constants/messsageMediaType';

export const conversation: NavItemType = {
  id: 'conversation',
  title: 'conversation',
  type: 'group',
  children: [
    {
      id: 'whatsapp',
      title: 'conversation',
      type: 'item',
      url: ROUTE_CONVERSATIONS,
      icon: (props) => <IconMessages style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_CONVERSATION,
      chip: {
        color: 'primary',
        variant: 'filled',
        size: 'small',
        label: '0'
      }
    }
  ]
};
