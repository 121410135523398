import { Box, useTheme } from '@mui/material';

export const WaterMark = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        opacity: 0.05,
        pointerEvents: 'none',
        zIndex: 9999,
        display: 'none',
        '@media print': {
          display: 'block'
        }
      }}
    >
      <img src="/kraquen-dark.png" />
    </Box>
  );
};
