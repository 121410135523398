import { NavItemType } from 'types';

import { IconWebhook, IconBrandFacebook } from '@tabler/icons';
import { ROUTE_META_LEADS_ADS, ROUTE_WEBHOOKS } from 'constants/routes';
import { PERMISSION_FACEBOOK_INTEGRATION, PERMISSION_WEBHOOK } from 'constants/permissions';
import { STYLES_ICON } from 'components/Chat/constants/messsageMediaType';

export const automation: NavItemType = {
  id: 'automation',
  title: 'automation',
  type: 'group',
  children: [
    {
      id: 'hooks',
      title: 'webhooks',
      type: 'item',
      url: ROUTE_WEBHOOKS,
      icon: (props) => <IconWebhook style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_WEBHOOK
    },
    {
      id: 'lead-ads',
      title: 'fb-integration',
      type: 'item',
      url: ROUTE_META_LEADS_ADS,
      icon: (props) => <IconBrandFacebook style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_FACEBOOK_INTEGRATION
    }
  ]
};
