/**
 * Get the call time in the format of HH:MM:SS
 *
 * @param {number} time
 * @return {*}
 */
export const getCallTime = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;
  return [hours, minutes, seconds].map((v) => (v < 10 ? '0' + v : v)).join(':');
};
