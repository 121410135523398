import { styled, useTheme, Theme } from '@mui/material/styles';
import { Container, AppBar, Box, CssBaseline, Toolbar } from '@mui/material';
import { Header, ReportHeader, WaterMark } from './components';
import useConfig from 'hooks/useConfig';
import { IReportLayout } from './types';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }: { theme: Theme }) => ({
  ...theme.typography.mainContent,
  marginTop: 88,
  '@media print': {
    marginTop: 0
  },
  marginLeft: 0,
  marginRight: 0
}));

export const ReportLayout: React.FC<IReportLayout> = ({ children }) => {
  const theme = useTheme();

  const { container } = useConfig();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          '@media print': {
            display: 'none'
          }
        }}
      >
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>

      <Main theme={theme}>
        <ReportHeader />
        <WaterMark />
        <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
          {children}
        </Container>
      </Main>
    </Box>
  );
};
