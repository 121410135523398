import { NavItemType } from 'types';
import { IconHome } from '@tabler/icons';
import { STYLES_ICON } from 'components/Chat/constants/messsageMediaType';
import { ROUTE_DASHBOARD } from 'constants/routes';

export const home: NavItemType = {
  id: 'home',
  title: 'home',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'home',
      type: 'item',
      url: ROUTE_DASHBOARD,
      icon: (props) => <IconHome style={STYLES_ICON} {...props} />
    }
  ]
};
