import { Box, Divider, Typography } from '@mui/material';
import { Fragment } from 'react';
import Logo from 'ui-component/Logo';

export const ReportHeader = () => {
  return (
    <Fragment>
      <Box
        sx={{
          paddingBottom: '1em',
          marginBottom: '1em',
          display: 'none',
          '@media print': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }
        }}
      >
        <Logo />
        <Typography variant="h4" color="textPrimary">
          Kraquen.io
        </Typography>
      </Box>
      <Divider
        sx={{
          display: 'none',
          '@media print': {
            display: 'block'
          }
        }}
      />
    </Fragment>
  );
};
