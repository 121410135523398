import { NavItemType } from 'types';
import { IconSettings } from '@tabler/icons';
import { PERMISSION_SETTINGS } from 'constants/permissions';
import { ROUTE_SETTINGS } from 'constants/routes';
import { STYLES_ICON } from 'components/Chat/constants/messsageMediaType';

export const settings: NavItemType = {
  id: 'settings',
  title: 'settings',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: 'settings',
      type: 'item',
      url: ROUTE_SETTINGS,
      icon: (props) => <IconSettings style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_SETTINGS
    }
  ]
};
