import Link from 'Link';
import { Link as MuiLink } from '@mui/material';
import { LOGIN_PATH } from 'config';
import Logo from 'ui-component/Logo';

export const LogoSection = () => {
  return (
    <MuiLink component={Link} href={LOGIN_PATH} aria-label="theme-logo">
      <Logo />
    </MuiLink>
  );
};
