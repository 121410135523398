export const STYLES_ICON = {
  width: 15,
  height: 15
};

export const STICKER_TYPE = 'image/webp';
export const IMAGE_TYPE = ['image/jpeg', 'image/png', 'image/gif'];
export const VIDEO_TYPE = ['video/mp4', 'video/avi'];
export const AUDIO_TYPE = ['audio/mp3', 'audio/ogg', 'audio/mp4'];
export const PDF_TYPE = 'application/pdf';
