import { cloneElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, useScrollTrigger } from '@mui/material';
import { HeaderContent } from './HeaderContent';
import { IElevationScroll } from './types';

const ElevationScroll = ({ children, window }: IElevationScroll) => {
  const theme = useTheme();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window!
  });

  return cloneElement(children, {
    elevation: trigger ? 1 : 0,
    style: {
      backgroundColor: theme.palette.mode === 'dark' && trigger ? theme.palette.dark[800] : theme.palette.background.default,
      color: theme.palette.text.dark
    }
  });
};

export const Header = ({ ...props }) => {
  return (
    <ElevationScroll {...props}>
      <AppBar>
        <HeaderContent />
      </AppBar>
    </ElevationScroll>
  );
};
