import { NavItemType } from 'types';
import { IconDatabase } from '@tabler/icons';
import { STYLES_ICON } from 'components/Chat/constants/messsageMediaType';
import { PERMISSION_LEADS } from 'constants/permissions';
import { ROUTE_LEADS } from 'constants/routes';

export const crm: NavItemType = {
  id: 'CRM',
  title: 'CRM',
  type: 'group',
  children: [
    {
      id: 'Leads',
      title: 'Leads',
      type: 'item',
      url: ROUTE_LEADS,
      icon: (props) => <IconDatabase style={STYLES_ICON} {...props} />,
      permissions: PERMISSION_LEADS
    }
  ]
};
