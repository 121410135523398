import { Box, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LanguageToggler } from '../../components/LanguageToggler';
import { LogoSection } from './LogoSection';
import useReport from 'hooks/useReport';
import { useTranslation } from 'hooks/useTranslation';
import useConfig from 'hooks/useConfig';
import moment from 'moment';
import 'moment/locale/es';

export const HeaderContent = () => {
  const theme = useTheme();

  const { translate } = useTranslation();

  const { report } = useReport();

  const { locale } = useConfig();

  return (
    <Toolbar>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2
        }}
      >
        <Typography component="div" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Typography>
        {report && (
          <Typography variant="caption" color="textSecondary">
            {translate('reportExpiresAt', { expiresAt: moment(report.expiresAt).locale(locale).format('LL') })}
          </Typography>
        )}
        <LanguageToggler
          sx={{
            borderColor: 'transparent',
            background: 'transparent',
            '&[aria-controls="menu-list-grow"],&:hover': {
              borderColor: 'transparent',
              background: 'transparent',
              color: theme.palette.primary.dark
            }
          }}
        />
      </Box>
    </Toolbar>
  );
};
